<template>
	<div class="p-grid">
		<div class="p-col-12">
			
			
			
			<!-- the list of assets -->
			
			<div class="card">
				<div class='metrics-header-row'>
					<span class="statement-title p-text-bold">Companies</span>
					<div class='right-aligned'>
						<span class="p-input-icon-right list-search-bar-container">
							<AutoComplete ref='listSearchBar' id='listSearchBar' placeholder="Select List" forceSelection v-model="selectedList" 
								:suggestions="filteredLists" @complete="searchLists($event)" field="name" @click="clearListInput($event)"
								@item-select="onListSelected($event)"
								style="border-radius:10px;"/>
							<i class="pi pi-search" />

						</span>
						<span class="p-input-icon-right">
							<AutoComplete placeholder="Add Company" ref="compSecSearch" id="listCompSecSearch" forceSelection v-model="selectedSecurity" 
								:suggestions="filteredSecurities" @complete="searchSecurities($event)" field="name" 
								@item-select="onSecuritySelected($event)"
								style="border-radius:10px;" @click="onAddSecInputBarClick" :disabled="disableManualAdd">
								<template #item="slotProps">
				
									<span class='symbol'>{{slotProps.item.symbol.replace("/", ".")}}</span><br>
									<small>
										{{(slotProps.item.company ? slotProps.item.company : slotProps.item.name)}}
										{{ (slotProps.item.exchangeSymbol ? " - "+getExchange(slotProps.item.exchangeSymbol): "")}}
									</small>
								</template>
							</AutoComplete>
							<i class="pi pi-search" />

						</span>
					</div>
				</div>
				<DataTable :value="rows"  class="p-datatable-sm data-point-table" id='listCompDataTable' 
					:scrollable="true" scrollHeight="275px" scrollDirection="horizontal" v-model:selection="selectedToGraph" :selectionMode="currentSelectionMode" dataKey="position" 
					:metaKeySelection="false"  ref="table"
					@row-select="onRowSelected" @row-unselect="onRowUnSelected"
					:lazy="true" :paginator="true" :rows='limitPerPage' :totalRecords="totalRecords" @page="onPage($event)"
					>

					<LoadingSpinner :loading='loadingData' v-if='loadingData'/>

					<template v-else>	
						<Column  header="Actions">
							<template #body="slotProps">
								<a :href="getViewCompanyLink(slotProps.data)"  class="p-button p-component p-button-icon-only view-item-button" v-tooltip.top="'Go to company overview'">
									<span class="pi pi-chart-line p-button-icon"></span><span class="p-button-label">&nbsp;</span>
								</a>
								<Button icon="pi pi-trash" @click="removeCompany(slotProps.data)" class="delete-note-button" v-tooltip.top="'Delete company from list'" />
							</template>
						</Column>
						<Column field="symbol" header="Symbol"></Column>
						<Column field="name" header="Name" style="min-width: 200px !important;"></Column>
						<Column header="% Change MTD">
							<template #body="slotProps">
								{{formatValue(slotProps.data.comparisonMeasureSnapshot['Percent Price Change MTD'])}}
							</template>
						</Column>
						<Column  header="% Change YTD">
							<template #body="slotProps">
								{{formatValue(slotProps.data.comparisonMeasureSnapshot['Percent Price Change YTD'])}}
							</template>
						</Column>
						<Column  header="Market Cap">
							<template #body="slotProps">
								{{(slotProps.data.lastTradingRecord? formatMarketCapValue(slotProps.data.lastTradingRecord.marketCap) : '-')}}
							</template>
						</Column>
						<Column  header="P/E(LTM)">
							<template #body="slotProps">
								{{formatValue(slotProps.data.comparisonMeasureSnapshot['PE Ratio'])}}
							</template>
						</Column>
						<Column  header="P/B(LTM)">
							<template #body="slotProps">
								{{formatValue(slotProps.data.comparisonMeasureSnapshot['Price To Book'])}}
							</template>
						</Column>
						<!-- <Column  header="P/CF">
							<template #body="slotProps">
								{{formatValue(slotProps.data.comparisonMeasureSnapshot['Price To Cash Flow'])}}
							</template>
						</Column> -->
						<Column  header="ROA">
							<template #body="slotProps">
								{{formatValue(slotProps.data.comparisonMeasureSnapshot['Return on Assets'])}}
							</template>
						</Column>
						<Column  header="RCE">
							<template #body="slotProps">
								{{formatValue(slotProps.data.comparisonMeasureSnapshot['Return On Common Equity'])}}
							</template>
						</Column>
						<Column header="1Y Growth Rate">
							<template #body="slotProps">
								{{formatValue(slotProps.data.comparisonMeasureSnapshot['1 Year Annual Revenue Growth Rate'])}}
							</template>
						</Column>
						<Column  header="EV">
							<template #body="slotProps">
								{{formatValue(slotProps.data.comparisonMeasureSnapshot['Total Enterprise Value'])}}
							</template>
						</Column>
					</template>
					
					<template #empty v-if='!loadingData'>
						<div style="text-align: center;">Nothing to show.</div>
					</template>
				</DataTable>
			</div>
			
			<!-- the comparison chart -->
			
			<div class='card'> 
				<div class='chart-header-row'>
					<span class="statement-title p-text-bold">Comparison Chart</span>
					<div class='right-aligned'>
						<SelectButton :class="{'period-toggle': true, 'disabled': periodTypesDisabled}" v-model="selectedPeriodType" :options="periodTypes" optionLabel="name"
							:disabled="periodTypesDisabled"/>
						<span class="p-input-icon-right" style="margin-right:10px;">
							<AutoComplete ref='listMetricSearchBar' id='listMetricSearchBar' placeholder="Select Metric" forceSelection v-model="selectedMetric" 
								:suggestions="filteredMetrics" @complete="searchMetrics($event)" field="name" @click="clearMetricInput($event)"
								@item-select="onMetricSelected($event)"
								style="border-radius:10px;">
								<template #item="slotProps">
				
									<span v-tooltip.left="getMetricDesc(slotProps.item)">{{slotProps.item.name}}</span>
									
								</template>
							</AutoComplete>
							<i class="pi pi-search" />

						</span>
						<MoreOptionsMenu class='more-options' @click="toggleMenu"/>
						<Menu ref="menu" :model="chartMenuItems" :popup="true" />
						
					</div>
				</div>
				<div class="selected-metric" v-if="selectedMetricName" @click="toggleShowDescriptionModal"><span class="p-text-bold" >{{selectedMetricName}}</span></div>
				<ComparisonChart  :selectedSymbol="selectedSymbol" key="comparisonChart"  ref="compChart" />
			</div>

		</div>
	</div>
	<AttributionFooter :authors="['quoteMedia', 'XIgnite']" />
	<ConfirmModal  :headerText='confirmDeleteText' confirmButtonLabel='Delete' @confirmed="deleteListConfirmed" @cancelled="deleteListCancelled" ref='confirmDeleteModal'/>
	<CreateListModal :headerText='createEditHeader' :listName='listName' @onSave="getSavedListName" ref='createEditListModal'/>
	<UpgradeToPremiumModal ref="upgradeToPremiumModal" :type="PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']" :bodyText="upgradeText" @cancel-upgrade="cancelUpgrade" />
	<Dialog id="listsItemDescriptionDialog" v-model:visible="showDescription"  :header="selectedMetricName"  :position="'top'" style="width:40rem;" >
    
    
        <div class="desc-content" >
			{{selectedMetricDescription}}
        </div>
        <template #footer>
            </template>
    
    </Dialog>
</template>

<script>

import LoadingSpinner from '../components/common/LoadingSpinner';
import AttributionFooter from '../components/footer/AttributionFooter.vue';
//import format from 'date-fns/format';
import numeral from 'numeral';
//import PillMenuBar from '../menu/PillMenuBar';
import ComparisonChart from '../components/charts/highcharts/ComparisonChart.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
//import DataService from '../service/DataService';
import ResearchService from '../service/ResearchService';
import SelectButton from 'primevue/selectbutton';
import MoreOptionsMenu from '../components/menu/MoreOptionsMenu';
import AutoComplete from 'primevue/autocomplete';
//import SearchDialog from '../equity/SearchDialog.vue';
import EventBus from '../event-bus';
import { mapState } from 'vuex';
import ConfirmModal from '../components/modal/ConfirmModal.vue';
import CreateListModal from '../components/modal/assetLists/CreateListModal.vue';

import {PREMIUM_SUBSCRIPTION_TYPES, BASIC_METRICS, PE_RATIO} from '../common/constants';
import UserUtils from '../utilities/UserUtils';
import UpgradeToPremiumModal from '../components/modal/UpgradeToPremiumModal.vue';

import DataService from '../service/DataService';
import Dialog from 'primevue/dialog';

const ANNUAL = 1;
const QUARTERLY = 2;
//const DAILY = 4;
const VALUE_TYPE_FORMATTER = {
	'Millions': '0.0a',
	'Ratio': '0.00',
	'Percent': '0.00%',
	'$ & cents': '0.00a',
	'Actual': '0.00',
	'Decimal Percentage' : '0.00%',
}

const METRICS = BASIC_METRICS;

const DEFAULT_COMPANY_LIST = [{'symbol': 'FB'}, {'symbol': 'AMZN'}, {'symbol': 'AAPL'}, {'symbol': 'NFLX'}, {'symbol': 'GOOG'}];

export default {
	data() {
		return {
			PREMIUM_SUBSCRIPTION_TYPES,
			filteredLists: [],
			selectedList: null,
			currentListOffset: 0,
			limitPerPage: 10,
			totalRecords: 0,
			loadingData: false,
			recordsShowing: 0,

			confirmDeleteText: '',

			createEditHeader: '',
			listName: '',


			justMounted: false,
			active: false,
			reloadItems: false,
			columns: null,
			isEditing: false,
			rows:[],
			disableManualAdd: true,
			currentSymbol: "",
			
			selectedToGraph: [],
			
			
			selectedSymbol: '',
			currentSelectionMode: "multiple",
			periodTypes: [{name:'Annual', value: ANNUAL}, {name:'Quarterly', value: QUARTERLY}],
			selectedPeriodType: null,
			scrollDirection: null,
			scrollable: false,
			selectedMetric: null,
			filteredMetrics: [],
			chartMenuItems: [
				{
                    label: 'Take Snapshot',
					
                    icon: 'pi pi-camera',
                    command: () => {
						this.$refs.compChart.openSnapshotDialog();
                    }
                },
               
			],
			periodTypesDisabled: true,
			selectedSecurity: {name:""},
			filteredSecurities: [],
			selectedMetricName: null,
			selectedMetricDescription: null,
			showDescription: false,
			upgradeText: 'To get full access to hundreds of metrics and the other premium features below, upgrade to premium.',
		}
	},
    components : {
        //PillMenuBar, 
		DataTable, Column,  SelectButton, MoreOptionsMenu, ComparisonChart,AutoComplete,
		AttributionFooter, ConfirmModal, CreateListModal, LoadingSpinner,UpgradeToPremiumModal,Dialog
		//SearchDialog,
    },
	created() {
	},
	mounted() {
		console.debug("mounting Lists");

		this.justMounted = true;

		

		this.$nextTick(() => {

			this.selectedPeriodType = this.periodTypes[0];
			if( !this.selectedMetric) {
				
				this.selectedMetric = (this.compareMetric ? this.compareMetric : PE_RATIO);
				this.onMetricSelected({value: this.selectedMetric});
			}
					
		});
		
	},
	activated() {
		EventBus.on('create-list', this.onCreateList);
		EventBus.on('edit-list', this.onEditList);
		EventBus.on('delete-list', this.onDeleteList);

		this.selectedList = this.recentlyViewedList;

		if (!this.selectedList) {
			ResearchService.getAssetLists(1, 0, '').then((resp) => {
				if( resp.data.status == "success"){
					if (resp.data.lists.length > 0) {
						this.onListSelected({value: resp.data.lists[0]});
					} else {
						const assetList = {
							userId : this.$store.state.users.user.userId,
							type: 'equity',
							name: 'Sample List - FAANG',
							description: '',
							assetItems: DEFAULT_COMPANY_LIST
						};

						ResearchService.createAssetList(assetList).then(resp => {
							if( resp.data.status === "success") {
								this.onListSelected({value: resp.data.assetList});
							} else {
								console.log("error saving new asset list "+ resp.data.message);
							} 

						})
						.catch(error => {
							console.log("error saving new asset list "+ error);

						});
					}
				}
			});
		} else {
			this.onListSelected({value: this.selectedList});
		}

		this.active = true;
		if( !this.selectedMetric) {
			
			this.selectedMetric = (this.compareMetric ? this.compareMetric: PE_RATIO);
			this.onMetricSelected({value: this.selectedMetric});
			
			this.resetTable();
			this.$nextTick().then(() => {
				if (this.active) {
					//this.fetchData();
					
				}
			});
		}

		if (this.reloadItems && this.selectedList) {
			this.loadListItems(true);
		}
	},

	unmounted() {
		
		this.resetTable();
		this.selectedList = null;
	},

	deactivated() {
		EventBus.off('create-list');
		EventBus.off('edit-list');
		EventBus.off('delete-list');
		this.active = false;
		this.isEditing = false;
		this.reloadItems = true;
	},

	computed: {
        ...mapState(['recentlyViewedList']),
		...mapState(['compareMetric']),
		isPremUser() {
			return UserUtils.isPremiumSubscriber();
		},
    },
    watch:{
		selectedList(val, oldVal) {//eslint-disable-line
			
			if( !this.selectedList) {
				this.showNoListSelected();
			}
		},

		selectedPeriodType() {
			

			if (this.active) {
				
				this.updatePeriods();
			}

		},
		selectedMetric() {
			if( this.selectedMetric == null ){
				this.periodTypesDisabled = true;
			}
		},
		rows: { // this is how you watch an array
			handler(newVal, oldVal) {//eslint-disable-line
				if( newVal.length < 100){
					this.disableManualAdd = false;
				}
				else {
					
					this.disableManualAdd = true;
				}
			},
			deep: true
		}
	},

	methods: {
		toggleShowDescriptionModal() {
			this.showDescription = !this.showDescription
			

		},
		getMetricDesc(m){
			if( m ){
				if(m.description){
					if(m.description.length > 500){
						return m.description.substring(0,500)+"...";
					}
					else {
						return m.description;
					}
				}
				else {
					return ""
				}
			}
			else {
				return "";
			}
			
		},
		showUpgradeModal() {
			this.$refs.upgradeToPremiumModal.open();
		},

		cancelUpgrade() {

		},

		getViewCompanyLink(item) {
			return '/index.html#/equity?sym='+item.symbol+'&assetType='+item.assetType+"&exSym="+item.exchangeSymbol;
		},
		viewCompany(item) {
			
			if( item.assetType == 'other') {
				ResearchService.setSelectedSymbolFromQM(item.symbol, (resp)=> {
          if (resp == 'success') {
            this.$router.push({ path: '/equity' });
          }
        });
			}
			else {
				ResearchService.setSelectedEquity(item.exchangeSymbol, item.symbol, () => {
      
					this.$router.push({ path: '/equity' });
				});
			}
		},

		onCreateList() {
			this.createEditHeader = "New List";
			this.listName = '';
			this.isEditing = false;
			this.$refs.createEditListModal.open();
		},

		onEditList() {	
			if( this.selectedList ) {
				this.createEditHeader = "Update List";
				this.listName = this.selectedList.name;
				this.isEditing = true;
				this.$refs.createEditListModal.open();
			}
		},

		getSavedListName(name) {
			this.listName = name;
			this.updateListName();
		},

		updateListName() {
			
			if( this.listName && this.listName.trim() != '') {
				if( this.isEditing ) {
					this.selectedList.name = this.listName.trim();
					ResearchService.updateAssetList(this.selectedList).then( resp => {
						if( resp.data.status == "success"){
							this.$toast.add({ severity: 'success', summary: 'Successfully updated!', life: 3000, group: 'center' });
							this.$store.commit('SET_RECENTLY_VIEWED_LIST', this.selectedList);
							this.$refs.createEditListModal.close();
						}
						else {
							console.log("error updating asset list "+ resp.data.message);
						}
					})
					.catch(error => {
						console.log("error updating new asset list "+ error);
					});
				}
				else {

					let assetList = {
						userId : this.$store.state.users.user.userId,
						type: 'equity',
						name: this.listName.trim(),
						description: '',
						assetItems: []
					};


					ResearchService.createAssetList(assetList).then(resp => {
						if( resp.data.status == "success"){
							this.$toast.add({ severity: 'success', summary: 'Successfully created!', life: 3000, group: 'center' });
							this.$refs.createEditListModal.close();
							this.onListSelected({value: resp.data.assetList});
							
						}
						else {
							console.log("error saving new asset list "+ resp.data.message);
						}
					})
					.catch(error => {
						console.log("error saving new asset list "+ error);
					});
				}

			}
		},

		onDeleteList() {
			
			this.confirmDeleteText = "Delete list '" +this.selectedList.name + "'?";
            this.$refs.confirmDeleteModal.open();
		},

		showNoListSelected() {
			this.resetTable();
			this.selectedToGraph = [];
			this.$refs.compChart.updateSecurities(this.selectedToGraph);
			EventBus.emit('list-unselected');
		},

		deleteListConfirmed() {
			ResearchService.deleteAssetList(this.selectedList.assetListId).then( resp => {
				if( resp.data.status == "success") {
					this.$toast.add({ severity: 'success', summary: 'Successfully deleted!', life: 3000, group: 'center' });
					this.selectedList = null;
					this.$store.commit('SET_RECENTLY_VIEWED_LIST', null);
				}
				else{
					console.log("error deleting list "+ resp.data.message);
				}
			})
			.catch(error => {
				console.log("error deleting list "+ error);
			});
		},

		deleteListCancelled() {

		},


		toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
		resetTable() {			
			this.selectedPeriodType = this.periodTypes[0];
			this.rows = [];
			this.columns = [];
			this.selectedToGraph = [];
			this.categories = [];
			this.totalRecords = 0;
			// this.selectedList = null;
			this.currentListOffset = 0;
			this.recordsShowing = 0;
			
		},

		searchLists(event) {
			ResearchService.getAssetLists(10, 0, event.query).then((resp) => {
				if( resp.data.status == "success"){
					this.filteredLists  = resp.data.lists;
					
				}
			});
		},

		onListSelected(event) {
			this.resetTable();
			this.$refs.compChart.updateSecurities(this.selectedToGraph);
			
			this.selectedList = event.value;
			this.$store.commit('SET_RECENTLY_VIEWED_LIST', this.selectedList);
			this.currentListOffset = 0;
			
			this.loadListItems(true);

			EventBus.emit('list-selected');

		},

		onPage(evt) {
			this.currentListOffset = evt.first;
            this.loadListItems();
        },

		loadListItems(preselectItems = false) {
			this.loadingData = true;
			ResearchService.getAssetListItems(this.selectedList.assetListId, this.limitPerPage, this.currentListOffset, null).then( (resp) => {
				
				if( resp.data.status == "success"){
					//console.log(JSON.stringify(resp.data));
					this.rows = resp.data.items;
					this.totalRecords = resp.data.total;
					this.recordsShowing = resp.data.items.length;
					this.loadingData = false;

					if (preselectItems) {
						this.selectedToGraph = this.rows.slice(0, 3);
						this.$refs.compChart.updateSecurities(this.selectedToGraph);
					}
				}
				else {
					console.error("error loading asset list items " + resp.data.message);
					this.loadingData = false;
				}
				
			}).catch(error => {
				console.error("error loading asset list items " + error);
					this.loadingData = false;
			});
		},



		getExchange(exchangeSymbol) {
            if( exchangeSymbol == 'XNAS') {
				return "NASDAQ";
			}
			else if( exchangeSymbol == 'XNYS') {
				return "NYSE";
			}
			else if( exchangeSymbol == 'OOTC') {
				return "OTC";
			}
			else if( exchangeSymbol == 'XTSE') {
				return "TSX";
			}
			else if( exchangeSymbol == 'XTSX') {
				return "TSXV";
			}
			else if( exchangeSymbol == 'XCNQ') {
				return "CSE"
			}
            else if( exchangeSymbol == 'ARCX'){
                return "NYSE ARCA"
            }
			else {
				return exchangeSymbol;
			}
        },
		searchSecurities(event){
            let type = "Equities"
            ResearchService.queryMarket(type, event.query, 10, 0).then(response => {
				
                let marketQueryResults = response.data;
                
                this.filteredSecurities = marketQueryResults.securities.filter((res) => {
					let alreadySelected = false;

					this.rows.forEach((sec) => {
						if (sec.securityId === res.securityId) {
							alreadySelected = true;
						}
					})

					return alreadySelected ? false : true;
				});
          })
          .catch(error => {
              this.filteredSecurities = []
              
            console.debug('There was an error:', error.response)
          })
        },

		clearListInput(e) {
			e['query'] = '';
            this.$refs.listSearchBar.$emit('complete', e)
			this.$refs.listSearchBar.show();
        },
		clearMetricInput(e) {
			e['query'] = '';
            this.$refs.listMetricSearchBar.$emit('complete', e)
			this.$refs.listMetricSearchBar.show();
			this.selectedMetric = {name:""};
        },

		onSecuritySelected(event) {
			let selected = event.value;
			this.selectedSecurity = { name: "", securityId: selected.securityId};
			//this.$toast.add({ severity: 'success', summary: 'Loading equity...', life: 2000, group: 'center' });
			this.loadingData = true;
			let assetListItem = {
				securityId : selected.securityId,
				lastTradingRecord : selected.lastTradingRecord,
				exchangeId : selected.exchangeId,
				exchangeSymbol: selected.exchangeSymbol,
				symbol: selected.symbol,
				name : selected.company,
				assetType : 'equity'
				
			}
			ResearchService.updateAssetListItems(this.selectedList.assetListId, [assetListItem], []).then( resp => {
				if( resp.data.status == "success") {
					this.$store.commit('SET_RECENTLY_VIEWED_LIST', this.selectedList);
					this.loadListItems();
				}
				else  {
					console.log("error adding asset list item " + resp.data.message);
					this.loadingData = false;
				}
			})
			.catch(error => {
					console.log("error adding asset list item " + error);
					this.loadingData = false;
			});

			

			
		},

		onAddSecInputBarClick() {
			this.selectedSecurity = { name: ""};
		},

		searchMetrics(event) {
			if( this.isPremUser ){
				let secId = 3;// default to bce
				if( this.selectedToGraph.length && this.selectedToGraph.length> 0 ) {
					secId = this.selectedToGraph[0].securityId;
				}
				DataService.searchMetrics(secId,null, event.query.toLowerCase(), 300, 0).then(resp => {
					if( resp.data.status == "success"){
						this.filteredMetrics = resp.data.results.data;
					}
					else {
						console.log("error searching metrics", resp.data);
					}
				}).catch(err => {
					console.log("error searching metrics", err);
				})
			}
			else {
				if (!event.query.trim().length) {
					this.filteredMetrics = [...METRICS];
				}
				else {
					this.filteredMetrics = METRICS.filter((metric) => {
						return metric.name.toLowerCase().startsWith(event.query.toLowerCase()) || metric.measureId==-1;
					});
				}
			}
		},
		onMetricSelected(event) {
			if (event.value.measureId == -1 ) {
				this.showUpgradeModal();
			}
			else {
				if( event.value.name != '' ){
					this.selectedMetricName = event.value.name;
					this.selectedMetricDescription = event.value.description;

				}
				if( this.isFiscalMetric(event.value) ){
					//enable period type
					this.periodTypesDisabled = false;
				}
				else {
					//disable period type
					this.periodTypesDisabled = true;
				}
				this.$refs.compChart.updateMetric(event.value);

				if( this.isPremUser ){
					DataService.saveCompareMetric(this.selectedMetric.measureId).then(resp => {
						if( resp.data.status == 'success'){
							this.$store.commit("SET_COMPARE_METRIC", this.selectedMetric);
						}
						else {
							console.log("error saving compare metric", resp.data);
						}
					}).catch(err => {
						console.log("error saving compare metric", err);
					})
				}
				else {
					this.$store.commit("SET_COMPARE_METRIC", this.selectedMetric);
				}
				
				if( document.getElementById('listMetricSearchBar')){
					this.$refs.listMetricSearchBar.$el.blur();
					document.getElementById('listMetricSearchBar').blur();
				}
			}
		},

		addSelectedProp(equity) {
			let obj = equity;

			obj['selected'] = false;

			return obj;
		},

		
		
        isFiscalMetric(selectedMetric){
           
             return (selectedMetric.periodTypes && (selectedMetric.periodTypes[0] == ANNUAL || selectedMetric.periodTypes[0] == QUARTERLY
				|| selectedMetric.periodTypes[0] == 'Yearly' || selectedMetric.periodTypes[0] == 'Quaterly')
			);
        },
		onRowSelected(event){//eslint-disable-line
			//this.toggleSelectionForEquity(event.data)
			if( event.data.assetType == 'other') {
				this.selectedToGraph.pop();
			}
			else {
				if( this.selectedToGraph.length > 10 ) {
					this.selectedToGraph.pop();
					this.$toast.add({severity:'warn', summary: 'Max Items', detail:'You can only chart up to 10 items. ', life: 3000, group: 'tc'});
				}
				else {
					this.$refs.compChart.updateSecurities(this.selectedToGraph);
				}
			}
		},

		filterOtherOut(item) {
			
			return (item.assetType != 'other');
		},

		onRowUnSelected(event){//eslint-disable-line
			//this.toggleSelectionForEquity(event.data)
			this.$refs.compChart.updateSecurities(this.selectedToGraph);
		},

		formatMarketCapValue(marketCap){
			if( marketCap ){
				return numeral(marketCap).format(VALUE_TYPE_FORMATTER['Millions']);
			}
			else {
				return '-';
			}
		},
		formatValue(measure) {
			//console.log("checking "+measure.measureValue)
			if( measure && measure.measureValue) {
				if( measure.valueType == 'Decimal Percentage'){
					return numeral(measure.measureValue).divide(100.0000).format(VALUE_TYPE_FORMATTER[measure.valueType]);
				}
				else if (measure.valueType == 'Ratio') {
					return numeral(measure.measureValue).format(VALUE_TYPE_FORMATTER[measure.valueType]) + 'x';
				}
				else {
					return numeral(measure.measureValue).format(VALUE_TYPE_FORMATTER[measure.valueType]);
				}
			}
			else {
				return "-";
			}
			

			
		},

		removeCompany(sec) {
			this.loadingData = true;
			this.rows = this.rows.filter(val => val.position !== sec.position);
			
			let assetListItem = {
				securityId : sec.securityId,
				assetListId: sec.assetListId,
				exchangeId : sec.exchangeId,
				exchangeSymbol: sec.exchangeSymbol,
				symbol: sec.symbol,
				name : sec.name,
				assetType : sec.assetType,
				position:  sec.position
			}
			ResearchService.updateAssetListItems(this.selectedList.assetListId, [], [assetListItem]).then( resp => {
				if( resp.data.status == "success") {

					let oldLength = this.selectedToGraph.length;
					this.selectedToGraph = this.selectedToGraph.filter(val => val.position !== sec.position);
					if( oldLength != this.selectedToGraph.length){
						this.$refs.compChart.updateSecurities(this.selectedToGraph);
					}

					this.$store.commit('SET_RECENTLY_VIEWED_LIST', this.selectedList);
					this.loadListItems();


				}
				else  {
					console.log("error removing asset list item " + resp.data.message);
					this.loadingData = false;
				}
			})
			.catch(error => {
					console.log("error adding asset list item " + error);
					this.loadingData = false;
			});

			

			
		},

		

		updatePeriods() {
			this.$refs.compChart.updatePeriodType(this.selectedPeriodType);
		}


	}
}
</script>

<style scoped>

*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.selected-metric{
	text-align: center;
	cursor: pointer;
}


::v-deep(.p-autocomplete-input.p-inputtext.p-component){
	width:21rem;
	border-radius: 8px;
}
::v-deep(.p-inputtext:enabled:hover) {
	border-color: #33CC99;
}
::v-deep(.p-inputtext:enabled:focus) {
	border-color: #33CC99;
	box-shadow: none;
}

.data-point-table {
	min-height: 100px;
}

.card {
	padding: 16px;
	width: 100%;
	border-radius: 16px;
	margin-bottom: 16px;
}
.card:last-child {
	margin-bottom: 0px;
}
.chart-header-row {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

::v-deep(.p-datatable-scrollable .p-datatable-wrapper::-webkit-scrollbar) {
	/* width */
	width: 10px;

	height: 10px;
}

::v-deep(.p-datatable-scrollable .p-datatable-wrapper::-webkit-scrollbar-track) {
	background-color: #F2F2F2;
	opacity: 1;
	transition: background-color .2s;
}

::v-deep(.p-datatable-scrollable .p-datatable-wrapper::-webkit-scrollbar-thumb) {
	background-color: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 20px;       /* roundness of the scroll thumb */
	border: 9px solid #BFBFBF;  /* creates padding around scroll thumb */
}
::v-deep(.data-point-table .p-datatable-thead > tr > th) {
	background: #ffffff;
	color: #999;
	/* padding-left: 2.75em; */
	font-weight: normal;
	min-width: 98px !important;
}
::v-deep(.data-point-table .p-datatable-thead > tr > th .p-column-title) {
	font-size: 12px;
}
::v-deep(.data-point-table .p-datatable-tbody > tr > td) {
	/* padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 38.5px; */
	min-width: 98px !important;
}
::v-deep(.data-point-table .p-datatable-tbody > tr.template-row) {
	color: black;
}
::v-deep(.data-point-table .p-datatable-tbody > tr.template-row > td) {
	padding-top: 5px;
	padding-bottom: 5px;
	font-weight: 550;
	font-size: 12px;
}
::v-deep(.data-point-table .p-datatable-tbody > tr > td) {
	padding-top: 5px;
	padding-bottom: 5px;
	
	font-size: 12px;
}
::v-deep(.data-point-table .p-datatable-tbody > tr:not(.p-highlight).industry-template-row:hover),
::v-deep(.data-point-table .p-datatable-tbody > tr:not(.p-highlight):hover),
::v-deep(.data-point-table .p-datatable-tbody > tr.p-highlight ){
	background: #32364E;
	color:#ffffff;
}

::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
    color: #32364e;
}
::v-deep(.p-paginator-first:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-prev:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-next:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-last:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover) {
    border-color: #32364e;
    background: #32364e;
    color: #FFFFFF;
}
::v-deep(.p-paginator-first:focus),
::v-deep(.p-paginator-prev:focus),
::v-deep(.p-paginator-next:focus),
::v-deep(.p-paginator-last:focus),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:focus) {
    box-shadow: none;
}


::v-deep(.negative) {
	color: #E63E3E;
}

.metrics-header-row {
	display: flex;
	margin-bottom: 10px;
} 

.right-aligned {
	display: flex;
	margin-left: auto;
}

.statement-title {
	font-size: 26px;
	color: #32364e;
}

.list-search-bar-container {
	margin-right: 8px;
}

::v-deep(.custom .p-button) {
        font-family: "Trebuchet MS", "Verdana";
 }

::v-deep(.period-toggle) {
	margin-right: 8px;
}
 ::v-deep(.period-toggle.disabled) {
	opacity: 0.4;
 }
 ::v-deep(.period-toggle .p-button.active),
    ::v-deep(.period-toggle .p-button:enabled:hover) {
        background: #32364E;
        color: #FFFFFF;
        
    }
	

	::v-deep(.period-toggle .p-button:focus) {
		box-shadow: none;
	}

	
	::v-deep(.period-toggle.p-selectbutton .p-button.p-highlight) {
		background: #32364E;
        color: #FFFFFF; 
		pointer-events: none;
		border-radius: 2rem;
		z-index: 2;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button ) {
		border-color: #32364E;
		padding-left: 20px;
		padding-right: 20px;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button:first-of-type ) {
		left: 20px;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button:not(.p-highlight):first-of-type) {
		border-radius: 2rem 0 0 2rem;
	}
		::v-deep(.period-toggle.p-selectbutton .p-button:not(.p-highlight):last-of-type) {
		border-radius: 0 2rem 2rem 0;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button:not(.p-highlight):hover) {
		background: #32364E;
        color: #FFFFFF; 
	}
	::v-deep(.period-toggle.disabled.p-selectbutton .p-button:not(.p-highlight):hover) {
		background: #FFFFFF;
        color: #32364E; 
		border-color: #32364E;
		cursor: default;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button-label) {
		padding: 0px;
	}

	::v-deep(.delete-note-button) {
    border: 1px solid #fa0505;
    background-color: #FFFFFF;
    color: #32364e;
    padding: 0px;
    height: 31px;
    border-radius: 30px;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-left: 8px;
}

::v-deep(.delete-note-button:hover) {
    border-color:#fa0505;
    background-color: #fa0505;
    color: #FFFFFF;
}
::v-deep(.delete-note-button:focus) {
    box-shadow: none;
    border-color: #fa0505;
}

::v-deep(.view-item-button) {
    border: 1px solid #32364e;
    background-color: #FFFFFF;
    color: #32364e;
    padding: 0px;
    height: 31px;
    border-radius: 30px;
    /* flex: 0 1 47px; */
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

::v-deep(.view-item-button:hover) {
    border-color:#32364e;
    background-color: #33cc99;
    color: #FFFFFF;
}
::v-deep(.view-item-button:focus) {
    box-shadow: none;
    border-color: #33cc99;
}

.list-modal .p-dialog-content > input{
    border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 40px;
    padding: 8px;
    flex: 1;
    color: #32364e;
    font-size: 16px;
	width: 100%;
}


.list-modal .p-dialog-footer .p-button {
	background: #FFFFFF;
	font-family: "Trebuchet MS", "Verdana";
	border-radius: 50px;
	padding: 8px 16px;
	color: #32364E;
	border: 2px solid;
	width: 100%;
 }

 .list-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 1rem;
 }

 .list-modal .p-dialog-footer .accept {
	border-color: #33cc99;
 }

 .list-modal .p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #33cc99;
	border: 2px solid #33cc99;
 }

 .list-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
	border: 2px solid #32364E;
 } 
</style>

<style >



.list-modal {
	font-family: "Trebuchet MS", "Verdana";
	box-shadow:  0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
	width: 300px;	
    border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

.list-modal .p-dialog-header {
	padding: 16px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.list-modal .p-dialog-header .p-dialog-title {
	font-weight: bold;
    font-size: 24px;
}

.list-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 16px;
}

.list-modal .p-dialog-footer {
	display: flex;
	align-items: center;
	padding: 16px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

</style>

